import { Link } from '../../../Link';
import { Stack, Text } from '@qcx/ui';
import { replaceAll } from '../../../../lib/replace-all.lib';

interface ContestsProps {
  title: string;
  variant?: string;
  children: React.ReactNode;
}

export const Contests = ({ title, children }: ContestsProps) => (
  <>
    <Stack flow="column" gap="$3">
      <Stack
        css={{ flexRowCenter: true, justifyContent: 'space-between' }}
        flow="row"
      >
        <Text css={{ color: '$neutral500' }} variant="sm" weight="semibold">
          {title}
        </Text>
        <Text
          as={Link}
          css={{ color: '$blue700' }}
          to={`/e/${replaceAll(title, ' ', '-').toLowerCase()}`}
          variant="sm"
          weight="medium"
        >
          Ver todos
        </Text>
      </Stack>
      {children}
    </Stack>
  </>
);
