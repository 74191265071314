import { Box, Icon, Stack, Text } from '@qcx/ui';
import { Link } from '../../../Link';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';

interface AlertProps {
  variant?: string;
  title?: string;
  to: string;
}

export const Alert = ({ variant, title, to }: AlertProps) => (
  <Box
    as={Link}
    css={{
      bgColor: `$${variant ?? 'neutral'}50`,
      h: 64,
      p: '$3',
      d: 'flex',
      flexRowCenter: true,
      rounded: '$2xl',
      position: 'relative',
      [`& span`]: {
        color: 'transparent',
        transition: 'color 0.1s ease-in-out'
      },
      [`&:hover span`]: {
        color: `$${variant ?? 'neutral'}600`
      },
      '&:before': {
        w: '$1',
        h: '$full',
        bgColor: `$${variant ?? 'neutral'}600`,
        content: '',
        d: 'flex',
        rounded: '$full'
      }
    }}
    title={title}
    to={to}
  >
    <Stack
      css={{
        w: '$full',
        pl: '$3',
        flexRowCenter: true,
        justifyContent: 'space-between'
      }}
      flow="row"
    >
      <Text
        as="p"
        css={{ color: '$neutral700', fontFamily: '$heading' }}
        variant="sm"
        weight="medium"
      >
        {title}
      </Text>
      <Icon icon={faChevronRight} size="md" />
    </Stack>
  </Box>
);
