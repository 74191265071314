/* eslint-disable @typescript-eslint/ban-ts-comment */

import { ComponentProps, useCallback } from 'react';
import { Link } from '../../../components/Link';
import { RegionIcon, RegionName } from './NewsRegion/RegionIcon';
import { Stack, Tabs, TabsList, TabsTrigger, Text, styled } from '@qcx/ui';
import { route } from '../../../route';

export type RegionLinksProps = ComponentProps<typeof Tabs>;
interface RegionLinksPropsExtend {
  exclude?: string[];
  value?: string;
  onFilterRegion?: (value: string) => void;
  icons?: boolean;
  id?: string;
  title?: string;
}

const REGIONS: RegionName[] = [
  'Federal',
  'Sudeste',
  'Nordeste',
  'Norte',
  'Sul',
  'Centro-Oeste'
];

const REGIONS_SLUGS: string[] = [
  'concursos-federais',
  'concursos-sudeste',
  'concursos-nordeste',
  'concursos-norte',
  'concursos-sul',
  'concursos-centro-oeste'
];

const TABS_VALUE: string[] = [
  'federal',
  'southeast',
  'northeast',
  'north',
  'south',
  'midWest'
];

export const RegionLinks = ({
  title,
  value,
  exclude,
  icons,
  onFilterRegion,
  ...rest
}: RegionLinksProps & RegionLinksPropsExtend) => {
  const handleChangeRegion = useCallback(
    (value: string) => {
      if (typeof onFilterRegion === 'function') {
        onFilterRegion(value);
      }
    },
    [onFilterRegion]
  );
  return (
    <Stack flow="column" gap="$4">
      {title && (
        <Text
          as="p"
          css={{
            color: '$neutral500'
          }}
          variant="sm"
          weight="semibold"
        >
          {title}
        </Text>
      )}
      {/* @ts-ignore */}
      <StyledTabWrapper defaultValue={value ?? 'nacional'} {...rest}>
        <StyledTabListWrapper aria-label={title ?? 'Navegação'}>
          {REGIONS.filter(region => !exclude?.includes(region)).map(
            (region, idx) => (
              <StyledTab
                key={`region_${idx}`}
                as={rest.variant === 'elevated' ? TabsTrigger : Link}
                id={rest.id ? `${rest.id}-${region}` : region}
                css={{
                  textTransform: 'capitalize'
                }}
                aria-controls={rest.id ? `${rest.id}-${region}` : region}
                role="tab"
                title={`Notícias ${region}`}
                to={route('editorials-show', { slug: REGIONS_SLUGS[idx] })}
                value={region}
                onClick={() => handleChangeRegion(TABS_VALUE[idx])}
              >
                <Stack
                  css={{ gap: '$2dot5', minW: 120, flexCenter: true }}
                  flow={'row'}
                >
                  {icons ? (
                    <RegionIcon
                      width={40}
                      height={40}
                      alt={`Ícone Região ${region}`}
                      region={region}
                    />
                  ) : null}
                  <Text variant={'sm'} weight={'semibold'}>
                    {region}
                  </Text>
                </Stack>
              </StyledTab>
            )
          )}
        </StyledTabListWrapper>
      </StyledTabWrapper>
    </Stack>
  );
};

const StyledTabListWrapper = styled(TabsList, {});
const StyledTab = styled(TabsTrigger, {
  color: '$blue800 !important',

  '&:hover, &:focus, &:active': {
    bgColor: '#FFF4ED'
  }
});
const StyledTabWrapper = styled(Tabs, {
  variants: {
    variant: {
      primary: {
        [`& ${StyledTab}`]: {
          bgColor: '#FFF9F7',
          color: '$neutral700 !important',
          borderRadius: 32,
          '&:hover, &:focus, &:active': {
            bgColor: '#FFF4ED',
            color: '#FE6112 !important'
          }
        }
      },
      elevated: {
        bgColor: '$neutral50',
        rounded: '$lg',
        overflow: 'hidden',
        [`& ${StyledTabListWrapper}`]: {
          bgColor: 'transparent',
          roundedB: '$lg',
          border: 0
        }
      }
    }
  }
});
