import { Box, Stack, Text, styled } from '@qcx/ui';
import { Link } from '../../../../components/Link';
import { MicroArticle } from '@atreus/data';
import { RegionIcon, RegionName } from './RegionIcon';
import { route } from '../../../../route';

interface NewsRegionProps {
  newsRegion: Record<string, MicroArticle[]>;
}

const REGIONS_VALUE: Record<string, string> = {
  Sudeste: 'southeast',
  Nordeste: 'northeast',
  Norte: 'north',
  Sul: 'south',
  'Centro-Oeste': 'midWest'
};

export const NewsRegion = ({ newsRegion }: NewsRegionProps) => {
  const regions: RegionName[] = [
    'Sudeste',
    'Nordeste',
    'Norte',
    'Sul',
    'Centro-Oeste'
  ];
  return (
    <Box>
      <Text css={{ color: '$neutral900' }} variant="xl" weight="semibold">
        Notícias por região
      </Text>
      <StyledWrapper css={{ my: '$10' }}>
        {regions.map((region, idx) => (
          <Stack key={`column_${idx}`} flow="column" gap="$5">
            <Stack
              css={{
                alignItems: 'center',
                '@xl': {
                  alignItems: 'flex-start'
                }
              }}
              flow={{ '@initial': 'row', '@xl': 'column' }}
              gap="$5"
            >
              <StyledWrapperIcon>
                <RegionIcon
                  width={40}
                  height={40}
                  alt={`Ícone Região ${region}`}
                  region={region}
                />
              </StyledWrapperIcon>
              <Text css={{ color: '$neutral800' }} variant="md" weight="medium">
                {region}
              </Text>
            </Stack>
            <StyledWrapperList>
              {newsRegion[REGIONS_VALUE[region]].map(
                (article: MicroArticle) => (
                  <li key={article.slug}>
                    <Link
                      title=""
                      to={route('news-show', {
                        slug: article.slug
                      })}
                    >
                      {article.title}
                    </Link>
                  </li>
                )
              )}
            </StyledWrapperList>
          </Stack>
        ))}
      </StyledWrapper>
    </Box>
  );
};

const StyledWrapper = styled('div', {
  flexCol: true,
  gap: '$8',
  d: 'grid',
  '@sm': {
    gridCols: '$1',
    gridRows: '$1'
  },
  '@md': {
    gridCols: '$3',
    gridRows: '$2'
  },
  '@xl': {
    gridCols: '$5',
    gridRows: '$1'
  }
});

const StyledWrapperList = styled('ul', {
  listStyleType: 'none',
  d: 'grid',
  flexCol: true,
  gap: '$3',
  '& a': {
    color: '$blue700',
    fontWeight: '$medium',
    fontSize: '$sm',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
});

const StyledWrapperIcon = styled('div', {
  w: '$12',
  h: '$12',
  bg: '#FFE6D5',
  border: '6px solid #FFF4ED',
  borderRadius: '$full',
  d: 'flex',
  flexCenter: true
});
