import { Box, ResponsiveImage } from '@qcx/ui';
import { Link } from '../../../../components/Link';
import { bannerDefault } from '../../../mocks/banner-default';

export type ImageDetails = {
  id: string;
  filename_disk: string;
  filename_download: string;
  description: string | null;
  width: number;
  height: number;
  title: string;
  type: string;
};

export type Banner = {
  id: string;
  status: string;
  title: string;
  alt: string;
  description: string | null;
  link: string;
  xsImage: ImageDetails;
  smImage?: ImageDetails;
  mdImage?: ImageDetails;
  lgImage?: ImageDetails;
  sort: string | null;
  tags: string[];
  buttons: unknown[];
  createdAt: string;
  updatedAt: string;
};

export type HomeBannerProps = {
  id: string;
  title: string;
  link: string;
  alt: string;
  smImage?: ImageDetails;
  mdImage?: ImageDetails;
  lgImage?: ImageDetails;
};

export const HomeBanner = ({
  id = bannerDefault.id,
  title = bannerDefault.title,
  link = bannerDefault.link,
  alt,
  smImage,
  mdImage,
  lgImage
}: HomeBannerProps) => {
  const isLocal = () => id === 'atreus-home-primary-default';
  const NEXUS_ASSETS_BASE_URL = global.ENV.PUBLIC_NEXUS_ASSETS_URL
    ? `${global.ENV.PUBLIC_NEXUS_ASSETS_URL}/`
    : '';
  const BASE_IMAGE_URL = isLocal() ? '' : NEXUS_ASSETS_BASE_URL;
  return (
    <Box
      as="div"
      css={{
        bgColor: '$neutral200',
        overflow: 'hidden',
        rounded: '$2xl',
        '@md': {
          rounded: '$4xl'
        }
      }}
      role="alert"
    >
      <Link
        css={{
          d: 'block'
        }}
        target="_blank"
        title={title}
        to={link}
      >
        <ResponsiveImage
          css={{
            '&, & img': {
              w: '$full',
              h: 'auto',
              d: 'block'
            }
          }}
          alt={alt}
          sources={[
            {
              src: `${BASE_IMAGE_URL}${smImage?.filename_disk}`,
              breakpoint: 'xs',
              width: 570,
              height: 299,
              quality: 70
            },
            {
              src: `${BASE_IMAGE_URL}${mdImage?.filename_disk}`,
              breakpoint: 'sm',
              width: 704,
              height: 641,
              quality: 70
            },
            {
              src: `${BASE_IMAGE_URL}${lgImage?.filename_disk}`,
              breakpoint: 'md',
              width: 1216,
              height: 148,
              quality: 70
            }
          ]}
          priority
        />
      </Link>
    </Box>
  );
};
