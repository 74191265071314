import { Banner } from './components/interventions/HomeBanner';
import { Box, Button, Container, Icon, Stack, useMounted } from '@qcx/ui';
import { HOME_SCHEMA } from '../../constants/schemas.constans';
import { HighlightsWeek } from '../../components/highlights/week/Week';
import { HomeAllNews } from './components/interventions/HomeAllNews';
import { HomeNews } from './components/interventions/HomeNews';
import { HomeService } from '@atreus/data';
import { HomeTabsRegion } from './components/HomeTabsRegion';
import { Link } from '../../components/Link';
import { ListBalloons } from './components/ListBalloons';
import { NewsRegion } from './components/NewsRegion';
import { Page } from '../../components/page/Page';
import { PageBellowTheFold } from '../../components/page/PageBellowTheFold';
import { PostSection } from '../../components/post/PostSection';
import { RecentNewsSection } from './components/RecentNewsSection';
import { Tracker } from '@atreus/tracker';
import { bannerDefault } from '../mocks/banner-default';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { generateCanonicalUrl } from '../../lib/generate-canonical-url.lib';
import { nexus } from '@kratos/nexus-client';
import { useMemo } from 'react';
import type { Route } from './+types/HomePage';

export const meta = ({ data }: Route.MetaArgs) => {
  if (!data) {
    return [];
  }

  const canonicalUrl = generateCanonicalUrl(data.url);

  return [
    { title: 'Folha Dirigida - Notícias para Concursos Públicos' },
    {
      name: 'description',
      content:
        'Tudo sobre concursos públicos: notícias, concursos abertos e editais previstos.'
    },
    { name: 'url', content: data.url },
    {
      tagName: 'link',
      rel: 'canonical',
      href: canonicalUrl
    },
    {
      'script:ld+json': HOME_SCHEMA
    }
  ];
};

export const headers = () => ({
  'Cache-Control': 'public, max-age=300, s-maxage=300'
});

const getValidBanner = async () => {
  try {
    const bannerResponse = await nexus.getBanner({ id: 'atreus-home-primary' });
    const isBannerValid =
      bannerResponse.banner?.status === 'published' &&
      bannerResponse.banner?.smImage &&
      bannerResponse.banner?.mdImage &&
      bannerResponse.banner?.lgImage;

    if (isBannerValid) {
      return bannerResponse.banner;
    }
  } catch {
    return bannerDefault;
  }
};

export const loader = async ({ request }: Route.LoaderArgs) => {
  const url = new URL(request.url);
  url.protocol = 'https';
  const homepageService = new HomeService();
  const homepage = await homepageService.getAtreusHome();
  const banner = await getValidBanner();
  const contentGroup = 'fd home';

  return {
    url: url.toString(),
    ...homepage,
    banner,
    contentGroup
  };
};

const HomePage = ({ loaderData }: Route.ComponentProps) => {
  const homeWidgetsFlags = {
    highlights: {
      active: true,
      title: 'Aqui você pode muito mais!',
      subtitle: 'Tudo o que você precisa para sua preparação em um só lugar.',
      cta: {
        link: 'https://www.qconcursos.com/conta/cadastrar',
        text: 'ESTUDE DE GRAÇA',
        event: 'home highlights widget',
        props: {
          target: '_blank'
        }
      }
    },
    news: {
      active: true,
      title: 'Quer pegar a sua vaga ainda em 2025?',
      subtitle: `Faça seu login e assista aulas para o concurso dos sonhos sem pagar nada.`,
      cta: {
        link: 'https://www.qconcursos.com/conta/cadastrar',
        text: 'COMECE AGORA',
        event: 'home last news widget',
        props: {
          target: '_blank'
        }
      }
    },
    all_news: {
      active: true,
      title: 'Você não está sozinho!',
      subtitle: `Da informação à aprovação, aqui seu cargo público se torna realidade.`,
      cta: {
        link: 'https://www.qconcursos.com/conta/cadastrar',
        text: 'PREPARE-SE',
        event: 'home all news widget',
        props: {
          target: '_blank'
        }
      }
    }
  };

  const {
    contests,
    articles,
    nationalHighlights,
    editorialsMostAccessedOfWeek,
    lastMostRecent,
    midWest,
    mostAccessedOfWeek,
    mostRecent,
    north,
    northeast,
    regionsHighlights,
    south,
    southeast,
    banner,
    contentGroup
  } = loaderData;

  const newsRegion = useMemo(
    () => ({ southeast, northeast, north, south, midWest }),
    [midWest, north, northeast, south, southeast]
  );

  useMounted(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Tracker?.track?.('news page viewed');

    if (window?.zaraz) {
      window.zaraz.set('content_group', contentGroup);
    }
  });

  return (
    <Page>
      <Container css={{ mb: '$16' }}>
        <Stack css={{ py: '$4' }} flow="column" gap="$6">
          <RecentNewsSection
            bannerHighlights={homeWidgetsFlags.highlights}
            homeBanner={banner as Banner}
            nationalHighlights={nationalHighlights}
          />
          <HomeTabsRegion
            federalHighlights={nationalHighlights}
            highlightsByRegions={regionsHighlights[0]}
          />
        </Stack>
      </Container>
      <Container>
        <Stack css={{ gap: '$8', '@lg': { gap: '$16' } }} flow="column">
          <HighlightsWeek
            contests={contests[0]}
            highlights={mostAccessedOfWeek!}
          />
          <Stack css={{ gap: '$4', '@lg': { gap: '$8' } }} flow="column">
            <PageBellowTheFold>
              <PostSection
                css={{
                  d: 'flex',
                  flexWrap: 'wrap'
                }}
                flow={{ '@xs': 'column', '@md': 'row', '@lg': 'column' }}
                postCardProps={{
                  elevated: false,
                  priority: false,
                  css: {
                    '& div > div': {
                      '@md': { minW: 288, w: 350 },
                      '@lg': { w: 'auto' }
                    }
                  }
                }}
                posts={mostRecent.slice(0, 2)}
                title="Últimas notícias"
              />
            </PageBellowTheFold>
            {homeWidgetsFlags.news?.active && (
              <HomeNews {...homeWidgetsFlags.news} />
            )}
            <PageBellowTheFold>
              <PostSection
                css={{
                  d: 'flex',
                  flexWrap: 'wrap'
                }}
                flow={{ '@xs': 'column', '@md': 'row', '@lg': 'column' }}
                postCardProps={{
                  elevated: false,
                  priority: false,
                  css: {
                    '& div > div': {
                      '@md': { minW: 288, w: 350 },
                      '@lg': { w: 'auto' }
                    }
                  }
                }}
                posts={mostRecent.slice(2, 4)}
              />
            </PageBellowTheFold>
            <Button
              as={Link}
              css={{
                minW: 120,
                justifyContent: 'center'
              }}
              aria-label="Ver mais notícias"
              rightIcon={<Icon icon={faChevronRight} size="sm" />}
              size="xl"
              // to={route('news-index')}
              to="/noticias"
              variant="primary"
              fullWidth
            >
              Ver mais notícias
            </Button>
          </Stack>
          <Box>
            <ListBalloons
              options={editorialsMostAccessedOfWeek}
              title="Editorias mais lidas da semana"
            />
          </Box>
          <PageBellowTheFold>
            <PostSection
              css={{
                '@md': {
                  flexWrap: 'wrap'
                }
              }}
              flow={{ '@xs': 'column', '@md': 'row', '@lg': 'column' }}
              postCardProps={{
                squared: true,
                priority: false,
                elevated: false,
                flow: {
                  '@xs': 'column',
                  '@sm': 'row'
                },
                css: {
                  '& div > div': {
                    '@md': { minW: 288, w: 350 },
                    '@lg': { w: 'auto' }
                  }
                }
              }}
              posts={articles}
              title="Artigos sobre concursos"
            />
          </PageBellowTheFold>
          <Button
            as={Link}
            css={{
              minW: 120,
              justifyContent: 'center'
            }}
            aria-label="Todos os artigos"
            rightIcon={<Icon icon={faChevronRight} size="sm" />}
            size="xl"
            to="/artigos"
            variant="primaryFaded"
            fullWidth
          >
            Todos os artigos
          </Button>
          <PageBellowTheFold>
            <PostSection
              flow={{ '@xs': 'column', '@md': 'row', '@lg': 'column' }}
              postCardProps={{
                elevated: false,
                priority: false,
                css: {
                  '& div > div': {
                    '@md': { minW: 288, w: 350 },
                    '@lg': { w: 'auto' }
                  }
                }
              }}
              posts={lastMostRecent.slice(0, 2)}
              title="Todas as notícias"
            />
          </PageBellowTheFold>
          {homeWidgetsFlags.all_news?.active && (
            <HomeAllNews {...homeWidgetsFlags.all_news} />
          )}
          <PageBellowTheFold>
            <PostSection
              flow={{ '@xs': 'column', '@md': 'row', '@lg': 'column' }}
              postCardProps={{
                elevated: false,
                priority: false,
                css: {
                  '& div > div': {
                    '@md': { minW: 288, w: 350 },
                    '@lg': { w: 'auto' }
                  }
                }
              }}
              posts={lastMostRecent.slice(2, 4)}
            />
            <NewsRegion newsRegion={newsRegion} />
          </PageBellowTheFold>
        </Stack>
      </Container>
    </Page>
  );
};

export default HomePage;
